.rounded-t-5 {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

@media (min-width: 992px) {
  .rounded-tr-lg-0 {
    border-top-right-radius: 0;
  }

  .rounded-bl-lg-5 {
    border-bottom-left-radius: 0.5rem;
  }
}

.nav-img {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.life-nav {
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.c33 {
  max-width: 572pt;
  padding: 72pt 9.8pt 72pt 13.5pt;
}
.c15 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c27 {
  font-size: 18pt;
  font-family: "Arial";
  color: #313e49;
  font-weight: 700;
}
ul.lst-kix_list_1-0 {
  list-style-type: none;
}
.c10 {
  padding: 0;
  margin: 0;
}
.c0 {
  margin-left: 36pt;
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: "Calibri";
}
.c1 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_1-0>li:before {
  content: "\0025cf  ";
}
.c14 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c28 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 14pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c15 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c13 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
}
ol.lst-kix_list_2-0.start {
  counter-reset: lst-ctn-kix_list_2-0 0;
}
.lst-kix_list_2-0>li {
  counter-increment: lst-ctn-kix_list_2-0;
}
.c22 {
  margin-left: 36pt;
  padding-top: 14pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c25 {
  font-size: 12pt;
  font-family: "Arial";
  color: #444444;
  font-weight: 700;
}
.c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c13 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c6 {
  font-size: 16pt;
  font-family: "Arial";
  color: #313e49;
  font-weight: 700;
}
.c16 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-family: "Arial";
  font-style: normal;
}
.c12 {
  font-size: 14pt;
  font-family: "Arial";
  color: #313e49;
  font-weight: 700;
}
.c13 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0;
  orphans: 2;
  widows: 2;
  text-align: left;
}
/* Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .hidden{
  display: none;
} */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  z-index: 1001;
  background-color: #fff;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
}

.modal-content {
  /* Add your styles for the modal content */
}
canvas {
  overflow-x: hidden !important; 
}
.desktop{
  display: none;

}
@media  (max-width: 726px) {
  .desktop{
    display: flex;
  
  }
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield; /* Firefox */
}